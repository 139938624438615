.date-calculator p {
  text-align: left;
  margin-bottom: -10px;
  margin-top: 20px;
}

.date-calculator span {
  text-align: left;
  margin-bottom: -10px;
  margin-top: 20px;
}

.date-calculator-box {
  display: flex;
  justify-content: space-between;
}

.date-calculator-days {
  margin-bottom: 40px !important;
}
.date-calculator-input {
  width: 48%;
}
