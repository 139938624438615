.success-logo {
  width: 210px;
  max-width: 60%;
  margin: 60px 0 -20px;
}

.success-people {
  max-width: 80%;
  width: 430px;
  display: block;
  margin: 10px 0 60px;
}

.success-check {
  margin: 10px 0 20px;
  color: #cecece;
}

.download-congrats {
  font-size: 2rem;
  max-width: 95%;
}

.download-button-desktop {
  display: flex;
}

.download-button-mobile {
  display: none;
}

@media (max-width: 890px) {
  .download-button-desktop {
    display: none;
  }

  .download-button-mobile {
    display: flex;
  }
}
