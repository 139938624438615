.wrapper {
  display: flex;
  justify-content: flex-start;
  position: relative;
  width: 100%;
}

.input {
  width: 100%;
  height: 46px;
  align-self: start;
  margin: 5px 0 20px;

  padding: 0 15px;
  border: 2px solid #d8e1fe;
  border-radius: 6px;
  font-size: 0.9rem;
  /* background-color: #ebeffb; */
  transition: all 0.2s;
  color: #5b55a0;
}

.input:focus {
  outline: none;
  background-color: #ffffff;
  padding-left: 18px;
  border: 2px solid #a8bbfd;
}

.textarea {
  width: 100%;
  height: 150px;
  align-self: start;
  margin: 5px 0 20px;
  font-size: 17.6px;
  padding: 5px 15px;
  border: 2px solid #d8e1fe;
  border-radius: 8px;
  font-size: 1rem;
  background-color: #fff;
  transition: all 0.2s;
  font-family: "Arial", sans-serif;
  color: #5b55a0;
  line-height: 1.8;
}

.textarea:focus {
  outline: none;
  background-color: #ffffff;
  padding-left: 18px;
  border: 2px solid #a8bbfd;
}

.text {
  width: 100%;
  line-height: 30px;
  text-align: left;
  font-weight: 700;
  font-size: 1rem;
}

.textSm {
  width: 100%;
  line-height: 30px;
  text-align: left;
  margin-bottom: -18px;
  margin-left: 4px;
}

.info {
  width: 100%;
  text-align: left;
  font-size: 0.9rem;
  padding-left: 28px;
  margin: 4px 0;
  animation: info-animation 0.8s;
}

@keyframes info-animation {
  100% {
    opacity: 1;
    transform: translateX(-10px);
  }

  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
}
