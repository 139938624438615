.electronic-signature-upload-button {
  width: 390px !important;
  position: absolute !important;
  right: 48px;
  top: 12px;
}

.electronic-signature-cancel {
  position: absolute;
  top: -11px;
  right: 80px;
  padding: 6px 12px;
  border-radius: 4px;
}

.electronic-signature-cancel svg {
  font-size: 0.9rem;
  position: relative;
  top: -1px;
}

.electronic-signature-action-box {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 5px 0 10px;
  align-items: center;
}

.electronic-signature-page-toggle-box {
  display: flex;
  /* transform: translateX(-50%); */
  width: 450px;
  justify-content: space-between;
  top: 4px;
  position: relative;
  z-index: 9;
}

.electronic-signature-page-toggle {
  background-color: rgb(241, 241, 253);
  color: rgb(196, 193, 223);
  height: 49px;
  width: 210px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* border: solid 1px rgb(226, 226, 226); */
  transition: all 0.12s;
  cursor: pointer;
  border-radius: 6px;
}

.electronic-signature-page-toggle-not-selected:hover {
  background-color: #fcfcab;
  color: grey;
  transition: all 0.2s;
  border: none;
  /* transform: translateY(-5%); */
}

.electronic-signature-page-toggle-selected {
  background-color: #a8bbfd;
  color: white;
}

.electronic-signature-remaining-box {
  position: absolute;
  top: 80px;
  right: 370px;
  font-size: 1rem;
  border-left: 4px solid #3cc691;
  padding-left: 15px;
  border-radius: 4px;
}

.activate-e-sign-wrapper {
  width: 100%;
  text-align: center;
}

.activate-e-sign-wrapper h2 {
  margin: 40px 0;
}

.activate-e-sign-wrapper img {
  margin: 180px 0;
  width: 400px;
}
