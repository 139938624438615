.modal-e-sign-form-checkbox-wrapper {
  height: 140px;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  align-items: flex-start;
  gap: 20px;
  padding-bottom: 20px;
}

.modal-e-sign-form-checkbox {
  padding-left: 10px;
}

.modal-e-sign-form-checkbox input {
  transform: scale(1.2);
  margin-right: 15px;
  opacity: 0.4;
}
