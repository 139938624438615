.data-uploader-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 70px;
  min-height: 130vh;
}

.data-uploader-card {
  margin-top: 40px;
  width: 600px;
  border-radius: 10px;
  box-shadow: 0px 14px 37px -8px rgba(0, 0, 0, 0.13);
  height: fit-content;
  padding: 12px 40px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

.data-uploader-card input {
  margin-top: 8px;
  width: 100%;
  height: 43px;
  border-radius: 4px;
  border: 1.6px solid powderblue;
  padding: 12px;
  font-size: 1rem;
  color: grey;
}

.data-uploader-card select {
  margin-top: 8px;
  width: 100%;
  height: 43px;
  border-radius: 4px;
  border: 1.6px solid powderblue;
  padding: 0 12px;
  font-size: 1rem;
  color: grey;
}

.data-uploader-field {
  width: 100%;
  margin: 14px 0;
}
