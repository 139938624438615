.dashboard-wrapper {
  display: grid;
  grid-template-columns: 80px auto;
}

.dashboard-wrapper-box {
  padding: 30px 40px 30px 30px;
  text-align: left;
  z-index: 1;
  max-width: 1700px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100vw - 97px);
}

.dashboard-wrapper-box-docx-editor {
  padding: 0;
  max-width: initial;
}

.dashboard-profile-warpper {
  margin: -30px;
  text-align: center;
}

.dashboard-message {
  text-align: left;
  font-size: 1rem;
  margin: 40px 0 20px;
  margin-left: 0;
}

.dashboard-show-mobile {
  min-height: 100vh;
  padding-top: 60px;
}

@media (max-width: 1050px) {
  .dashboard-no-show-mobile {
    display: none;
  }
}

@media (min-width: 1051px) {
  .dashboard-show-mobile {
    display: none;
  }
}

.dashboard-loading-screen-wrapper {
  min-height: 100vh;
  padding-top: 200px;
}
