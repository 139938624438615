.intro-mobile {
  display: none;
}

@media (max-width: 755px) {
  .intro-mobile {
    display: initial;
    min-height: 100%;
  }
  .intro-mobile-after-title {
    margin-top: -40px;
  }

  .moove-right-mobile {
    transform: translateX(-4%);
  }

  .use-case-icon-mobile {
    margin-right: 15px;
    font-size: 1.3rem;
  }
  .mobile-use-case {
    margin: 50px 0;
    text-align: left;
    margin-left: 20px;
  }
}
