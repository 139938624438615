.info-not-ready-text-box {
  width: 100%;
  text-align: left;
  margin: 20px 0;
}

.info-not-ready-title {
  margin-bottom: 10px;
}

.info-not-ready-button {
  width: fit-content;
  padding: 10px 10px;
  justify-self: end;
  align-self: flex-end;
  border-radius: 5px;
  transition: all 0.3s;
  cursor: pointer;
}

.info-not-ready-button:hover {
  background-color: #e8f3ff;
  color: #5b55a0;
}

.info-not-ready-wrapper {
  display: flex;
  flex-direction: column;
}
