.document-card {
  height: 65px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-left: 34px;
  position: relative;
  flex-direction: column;
  text-align: left;
  font-size: 0.9rem;
  transition: all 0.1s ease-in-out;
  box-shadow: 0px 8px 9px -5px rgba(185, 185, 185, 0.05);
  border-radius: 6px;
  background-color: white;
  margin-bottom: 20px;
  cursor: pointer;
  max-width: 1800px;
}

.document-card:hover {
  background-color: #5b55a0;
}

.document-card-text-bold {
  z-index: 2;
  font-size: 1rem;
  font-weight: 800;
  color: rgb(165, 165, 236);
}

.document-card-start-div {
  z-index: 2;
  top: 0px;
  position: relative;
}

.document-card:hover .document-card-start-div {
  color: white;
}

.document-card:hover .document-card-text-bold {
  color: white;
}
.document-card-end-div {
  position: absolute;
  z-index: 2;
  top: 15px;
  right: 24px;
  font-size: 0.8rem;
  color: rgb(156, 156, 156);
  opacity: 0;
  transition: all 0.2s ease-in-out 0.05s;
}

.document-card-green-span {
  color: #a8bbfd;
  margin-right: 8px;
  transition: all 0.2s;
}

.document-card-green-span:hover {
  color: #80ff66;
}

.document-card-logo {
  width: 75%;
}

.document-card-logo-company {
  width: 50%;
}

.logo-background {
  position: absolute;
  height: 100%;
  width: 140px;
  bottom: 0;
  right: 0;
  border-radius: 0 6px 6px 0;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.5s ease-in-out;
}

.logo-background.fade-in {
  opacity: 1;
}

.new-duplicate {
  animation: fadeInBackground 1.5s ease-in-out;
}

@keyframes fadeInBackground {
  0% {
    background-color: #5b55a0;
    opacity: 1;
  }
  100% {
    background-color: white;
    opacity: 1;
  }
}

.new-duplicate .document-card-start-div {
  animation: fadeInText 1.5s ease-in-out;
}

.new-duplicate .document-card-text-bold {
  animation: fadeInTextBold 1s ease-in-out;
}

@keyframes fadeInText {
  0% {
    color: white;
  }
  100% {
    color: inherit;
  }
}

@keyframes fadeInTextBold {
  0% {
    color: white;
  }
  100% {
    color: rgb(165, 165, 236);
  }
}

@keyframes fadeInDocumentCard {
  from {
    opacity: 0;
    background-color: var(--color-pallette-dark);
  }
  to {
    opacity: 1;
    background-color: transparent;
  }
}

.document-card.new {
  animation: fadeInDocumentCard 0.8s ease-in-out;
}

@media (max-width: 508px) {
  .document-card {
    font-size: 0.8rem !important;
    padding-left: 20px;
  }

  .document-card-text-bold {
    z-index: 2;
    font-size: 0.9rem;
    font-weight: 800;
    color: rgb(165, 165, 236);
  }
}
