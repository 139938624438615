.multiple-options-option-card {
  width: 100%;
  height: 55px;
  border-radius: 4px;
  margin: 18px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: all 0.2s;
  transform: scale(1);
  padding: 0 20px;
  font-size: 1rem;
  cursor: pointer;
  background-color: #f6f9fc;
}

.multiple-options-option-card-false:hover {
  transform: scale(1.04);
  background-color: #cbfdb4;
  background-color: #e8f3ff;
  color: #6b9e53;
}

.multiple-options-option-card-true {
  background-color: #cbfdb4;
  background-color: #e8f3ff;
  color: #6b9e53;
}

.multiple-options-square {
  margin-right: 28px;
  color: #37317d;
}

.multiple-options-check {
  margin-right: 28px;
  color: #6b9e53;
  transform: scale(1.07);
}

@media (max-width: 550px) {
  .multiple-options-option-card {
    font-size: 0.93rem;
  }

  .multiple-options-option-card-false:hover {
    transform: scale(1);
    background-color: rgb(233, 233, 233);
    color: rgb(184, 141, 253);
  }
}

.big-options-invisible {
  opacity: 0;
  height: 4px;
}

.big-options-checkbox {
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #cacece;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  opacity: 0.3;
  position: absolute;
  padding: 6px;
  left: 20px;
}

/* .big-options-checkbox:checked {
  background-color: #e9ecee;
  border: 1px solid #adb8c0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
    inset 15px 10px -12px rgba(255, 255, 255, 0.1);
  color: #99a1a7;
} */
