.percentage-wrapper{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.percentage-input-full{
    width: 140px;
    margin-right: 10px;
}

.percentage-decimals{
    width: 110px;
    margin-right: 10px;
}