.folder-wrapper {
  margin: 0 0 15px;
}

.folder-wrapper-is-open-true {
  margin: 0;
}

.folder-box {
  height: 60px;
  width: 100%;
  background-color: white;
  border-radius: 6px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  transition: all 0.13s;
  border-bottom: 1px solid rgb(233, 233, 233);
  justify-content: space-between;
}

.folder-box-opener {
  width: 100%;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 20px;
  transition: all 0.2s;
  font-size: 1rem;
}

.folder-box-opener:hover {
  transform: scale(0.99);
  color: rgb(98, 98, 245);
}

.folder-button-wrapper {
  display: flex;
}

.folder-box-icon {
  margin-right: 8px;
  font-size: 1rem;
}

.folder-table-wrapper {
  padding: 20px 0;
  margin: 80px 0 -50px;
}

.folder-box-quantity {
  margin-left: 10px;
  color: rgb(179, 179, 179);
  /* font-style: italic; */
  font-size: 0.95rem;
  font-weight: bold;
}

.folder-box-button {
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 43px;
  min-width: 43px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2rem;
  transform: scale(1);
  transition: all 0.12s;
}

.folder-search-button {
  margin-right: 20px;
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  min-width: 94px;
  height: 43px;
  border-radius: 5px;
  align-items: center;
  padding: 0 15px;
  font-size: 0.9rem;
}

.folder-box-button:hover {
  transform: scale(1.14);
  color: rgb(42, 33, 121);
}
