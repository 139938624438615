.left-bar {
  min-height: calc(100vh - 74px);
  width: 80px;
  z-index: 30;
  box-shadow: 4px 29px 19px 0px rgba(0, 0, 0, 0.06);
  /* border-right: solid 2px #f9f7ff; */
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-top: 10px;
  transition: all 0.15s ease-out;
  padding: 0 12px;
}

.left-bar:hover {
  width: 300px;
  box-shadow: 4px 29px 19px 0px rgba(0, 0, 0, 0.16);
}

.left-bar-user-icon {
  font-size: 1.6rem;
  margin-right: 12px;
  margin-top: 30px;
}

.left-bar-user {
  margin: 5px 0 3px;
  font-weight: bolder;
  font-size: 1.2rem;
}



.left-bar-company {
  margin: 4px 0 20px;
  /* font-weight: bolder; */
  font-size: 1rem;
}

.left-bar-element {
  text-align: left;
  height: 50px;
  width: 100%;
  /* border-bottom: 1px solid rgb(212, 215, 255); */
  cursor: pointer;
  transition: all 0.15s;
  position: relative;
  border-radius: 6px;
  margin: 5px 0;
  background-color: #f6f9fc;
  padding-left: 16px;
  padding-top: 16px;
  display: flex;
}

.left-bar-element-selected-false:hover {
  background-color: #e8eeff;
  transform: scale(1.04);
}

.left-bar-name {
  text-align: left;
  opacity: 0;
  width: 90%;
  position: absolute;
  width: 200px;
  left: 40px;
  top: 0px;
  font-size: 0.85rem;
  color: #5b55a0;
  pointer-events: none;
  
}

.left-bar:hover .left-bar-name {
  opacity: 0.8;
  transition: all 0.23s 0.4s;

}

.left-bar-element-content {
  display: flex;
  position: relative;
}


.left-bar-element-content-selected-true {
  /* transform: translateX(5%); */
  transition: all 0.3s ease-in-out 0.3s;
}

.left-bar-element i {
  font-size: 1.05rem;
  transition: all 0.3s;
}

.left-bar:hover i {
  color: #5b55a0;
  transform: scale(0.88);
}

.left-bar-element-selected-true i {
  color: #5b55a0;
}

.left-bar-element-icon-box{
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
}



