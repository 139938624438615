.catastro-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    padding-top: 40px;
}

.catastro-label{
    margin-bottom: -10px;
}