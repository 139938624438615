* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

/* color palette

casi negro
222831
gris oscuro
393e46
amarillito
ffd369
casiblanco
eeeeee */

body {
  color: #5b55a0;
  font-weight: 400;
  font-style: normal;
  text-align: center;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.1rem;
  font-size: 1.1rem;
}

button {
  color: #5b55a0;
  font-weight: 400;
  font-style: normal;
  text-align: center;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.1rem;
  font-size: 1.1rem;
}

div ::-webkit-scrollbar {
  width: 1px;
  border: none !important;
}

div ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(84, 78, 78, 0);
  border-radius: 2px;
  opacity: 0;
  background: white !important;
}

/* Handle */
div ::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 2px;
  opacity: 0;
}

.rest-of-app {
  min-height: 400px;
  position: relative;
}

@media (max-width: 570px) {
  .mobile-padding {
    padding: 0 10px;
  }
}

h1 {
  font-family: 'Roboto', sans-serif;
  /* text-transform: uppercase; */
  font-weight: 900;
  font-style: normal;
  font-size: 3rem;
  margin: 20px 0;
  letter-spacing: -2px;
  /* font-stretch: semi-condensed; */
}

.roboto {
  font-family: 'Roboto', sans-serif;
  /* text-transform: uppercase; */
  /* font-weight: 900;
  font-style: normal;
  letter-spacing: -3px; */
  /* font-stretch: semi-condensed; */
}

.roboto-b {
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
}

.thin {
  font-weight: 300;
  font-size: 2rem;
}

.bold {
  font-weight: 900;
}

.f-large {
  font-size: 2rem;
}

.f-1 {
  font-size: 1rem;
}

.f-standard {
  font-size: 1.1rem;
}

.f-medium {
  font-size: 1.4rem;
}

.f-small {
  font-size: 0.9rem;
}

.a-left {
  text-align: left;
}

.a-center {
  text-align: center;
}

.secondary {
  color: #e8eeff;
}

.font-color {
  color: #5b55a0;
}

.primary {
  color: #a8bbfd;
}

.tertiary {
  color: #fcfcab;
}

.fourth {
  color: #37317d;
}

.black {
  color: #040005;
}

.grey {
  color: #818181;
}

.green {
  color: #69fa69;
}

.green2 {
  color: #50c950;
}

.red {
  color: #f2695d;
}

.light-red {
  color: #fcb4ae;
}

.purple {
  color: #8178e2;
}

.disabled-grey {
  color: #d2d9ea;
}

.b-dark {
  background-color: #5b55a0;
}

.b-primary {
  background-color: #a8bbfd;
  color: white;
}

.b-primary-hover:hover {
  background-color: #fcfcab;
  color: grey;
  transition: all 0.2s;
}

.b-secondary {
  background-color: #e8f3ff;
}

.b-secondary-gradient {
  background-color: #e8eeff;
}

.b-secondary-hover:hover {
  background-color: #edede6;
  color: grey;
  transition: all 0.2s;
}

.b-tertiary {
  background-color: #f6f9fc;
}

.b-tertiary-hover:hover {
  background-color: #a8bbfd;
}

.b-light {
  background-color: #f1f1f1;
}

.b-white {
  background-color: #ffffff;
}

.b-white2 {
  background-color: #f5f6f7;
}

.b-green {
  background-color: #6983dd;
}

.b-table-green {
  background-color: rgb(172, 216, 147);
}

.b-red {
  background-color: #f2695d;
  color: white;
}

.b-light-red {
  background-color: #fb9188;
  color: white;
}

.b-red-hover:hover {
  background-color: #fcfcab;
  color: grey;
  transition: all 0.2s;
}

/* general-display props */

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.center {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.center-div {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.center-text {
  text-align: center;
}

.center-100-text {
  width: 100%;
  text-align: center;
}

.flex {
  display: flex;
}

.flex-start {
  display: flex;
  align-items: flex-start;
}

.flex-align {
  display: flex;
  align-items: center;
}

.flex-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.flex-center-v {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-end {
  justify-self: flex-end;
}

.grid {
  display: grid;
}

.grid-center {
  justify-content: center;
  align-items: center;
}

.grid-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.grid-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.grid-4 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.mobile-only {
  display: none;
}

@media (max-width: 970px) {
  .grid-mobile-large {
    display: grid;
    grid-template-columns: 1fr;
  }
}

@media (max-width: 1070px) {
  .grid-mobile {
    display: grid;
    grid-template-columns: 1fr;
  }

  .mobile-disapear {
    display: none;
  }

  .mobile-only {
    display: initial;
  }

  .mobile-margin {
    margin: 25px 0;
  }
}

/* image sizes */

.image-small {
  width: 100px;
}

.image-medium {
  width: 200px;
}

.image-large {
  width: 400px;
}

.image100 {
  width: 100%;
}

.image90 {
  width: 90%;
}

.yellow {
  color: #ffd369;
}

.light-yellow {
  color: #fff895;
}

.back {
  background-color: #393e46;
}

.yellow-back {
  background-color: #ffd369;
}

.light-yellow-back {
  background-color: #fff895;
}

.border-radius {
  border-radius: 4px;
}

.no-decor {
  text-decoration: none;
}

/* margin */

.margin-v {
  margin: 20px 0;
}

.margin-v-l {
  margin: 50px 0;
}

.margin-h {
  margin: 0 20px;
}
.margin-t {
  margin-top: 20px;
}
.margin-b {
  margin-bottom: 20px;
}

.margin-l {
  margin-left: 20px;
}
.margin-r {
  margin-right: 20px;
}

.margin-icon {
  margin-right: 10px;
}

.icon-margin-l {
  margin-left: 10px;
}

.ml1 {
  margin-left: 10px;
}

.icon-margin-r {
  margin-right: 10px;
}

.mr1 {
  margin-right: 10px;
}

/* width */

.width100 {
  width: 100%;
}

.width90 {
  width: 90%;
}

.width80 {
  width: 80%;
}

.width50 {
  width: 50%;
}

textarea {
  resize: none;
}

.logo-home {
  width: 700px;
  max-width: 90%;
  margin: 90px 0 140px;
}

input::placeholder {
  opacity: 0.3;
  color: rgb(130, 98, 247);
}

.elipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.MuiSvgIcon-root {
  color: #5b55a0;
}

.btn-outline {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px 12px;
  border-radius: 4px;
  border: 2px solid #a8bbfd;
  cursor: pointer;
  color: #5b55a0;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  font-size: 0.8rem;
  transition: all 0.2s;
}

.btn-outline svg {
  color: #a8bbfd;
  transition: all 0.5s;
}

.btn-outline:hover {
  background-color: #a8bbfd;
  color: white;
}

.btn-outline:hover svg {
  color: white;
  transform: translateX(-12px) scale(1.39) rotate(180deg);
}


/* Animations */

.animate {
  animation: slowFadeIn 1s;
}

@keyframes slowFadeIn {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}