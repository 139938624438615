.checker-section-card {
  padding: 30px 70px;
  background-color: rgb(255, 255, 255);
  max-width: 680px;
  min-height: 200px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 22px 15px -10px rgba(0, 0, 0, 0.13);
  box-shadow: 0px 9px 6px -5px rgba(0, 0, 0, 0.13);
  transition: all 0.2s ease-out;
  margin: 40px 0;
  border-radius: 12px;
}

.check-section-title {
  margin: 14px 0 0;
}

.checker-section-divider {
  background-color: rgb(237, 235, 255);
  width: 100%;
  height: 1px;
  border-radius: 3px;
  margin: 20px 0;
}

.checker-section-editor {
  height: 50px;
  width: 140px;
  border-radius: 4px;
  font-size: 1.1rem;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  align-items: center;
  cursor: pointer;
  margin-top: 40px;
  transition: all 0.2s ease-in;
}

@media (max-width: 633px) {
  .check-section-title {
    font-size: 1.1rem;
    margin-bottom: 10px;
  }

  .check-section-section {
    font-size: 0.9rem;
    margin-bottom: 10px;
  }
  .checker-section-card {
    padding: 30px 5%;
    background-color: rgb(255, 255, 255);
    font-size: 0.9rem;
    width: 100%;
  }

  .check-section-wrapper {
    font-size: 1rem;
    padding-top: 40px;
  }

  .check-section-instructions {
    font-size: 1.1rem;
    margin: 0 0 20px;
  }
}

@media (max-width: 1100px) {
  .contract-use-mobile {
    margin-bottom: 500px !important  ;
  }
}
