.upload-modal-body {
  background-color: white;
  position: relative;
  top: 7%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  border-radius: 6px;
  padding: 0px 0px;
}

.upload-modal-upload-button {
  margin-top: 30px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  margin-bottom: 0;
}
.upload-modal-close {
  font-size: 1.4rem;
  color: rgb(170, 170, 228);
  margin-bottom: 10px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}

.upload-modal-title {
  font-size: 1.4rem;
}

.upload-modal-loader {
  margin-top: 100px;
  margin-bottom: 20px;
}

.upload-modal-file-input {
  background-color: red;
}

.upload-modal-file-input {
  display: none;
}

.upload-modal-select-button {
  border: 0;
  border-radius: 6px;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.13s, color 0.05s;
  position: relative;
  font-size: 1.1rem;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  height: 60px;
  padding: 0 20px;
}

.upload-modal-button-icon {
  margin-right: 7px;
  font-size: 1.3rem;
}

.upload-modal-file-background {
  font-size: 4rem;
  margin: 30px 0;
}

.upload-modal-file-types {
  font-size: 0.87rem;
}

.upload-modal-drop-zone {
  width: 100%;
  border-radius: 4px;
  padding: 10px 0 40px;
  margin-bottom: 20px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23D7D3F0FF' stroke-width='7' stroke-dasharray='30' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 4px;
}
