.company-document-generator-title {
  margin: 40px 0;
}

.company-document-generator-check {
  font-size: 1.4rem;
  margin-left: 10px;
}

.company-document-generator-document-name {
  font-size: 1.1rem;
}

.company-document-generator-document-name-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  margin-bottom: 10px;
}

.company-document-generator-atom {
  width: 240px;
  animation: atom-animation 2s infinite, atom-rotation 2s infinite;
  margin-bottom: 30px;
}

.company-document-generator-rocket {
  margin-top: -10px;
  width: 260px;
}

@keyframes atom-animation {
  100% {
    opacity: 0.05;
  }

  50% {
    opacity: 1;
  }

  0% {
    opacity: 0.05;
  }
}

@keyframes atom-rotation {
  100% {
    transform: scale(0.8) rotate(0deg);
  }
  50% {
    transform: scale(1) rotate(20deg);
  }

  0% {
    transform: scale(0.8) rotate(0deg);
  }
}

.company-document-generator-entry {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 300px;
  text-align: left;
  margin: 29px;
}

.company-document-generator-entry-1 {
  animation: entry-animation 1s ease-in;
}

.company-document-generator-entry-2 {
  animation: entry-animation 2s ease-in;
}

.company-document-generator-entry-3 {
  animation: entry-animation 3s ease-in;
}

@keyframes entry-animation {
  100% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }

  0% {
    opacity: 0;
  }
}
