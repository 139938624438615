.policy-wrapper {
  padding: 40px 12%;
}

.policy-wrapper-small {
  padding: 40px 12%;
  max-width: 1300px;
  padding-bottom: 170px;
}

.policy-logo {
  width: 200px;
  margin: 40px 0;
}
