.empresas {
  padding: 50px 0;
  position: relative;
}

.empresas-lightning {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 800px;
  max-width: 100%;
}

@media (min-width: 1140px) {
  .empresas-lightning {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-40%);
    width: 800px;
    max-width: 100%;
  }
}

.empresas-text-box {
  position: relative;
}

.empresas-card {
  width: 310px;
  border-radius: 8px;
  padding: 20px 10px 10px;
  box-shadow: 0px 14px 37px -8px rgba(0, 0, 0, 0.13);
  margin-top: 50px;
}

.empresas-card-titulo {
  font-size: 1.35rem;
  font-weight: 400;
  margin-bottom: 10px;
  position: relative;
}
.empresas-card-disclaimer {
  font-size: 0.8rem;
  margin-bottom: 20px;
}

.empresas-card-entry {
  text-align: left;
  margin: 16px 0;
  font-size: 0.9rem;
}

.empresas-card-price {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 2rem;
  letter-spacing: -1;
  margin-bottom: 8px;
  font-weight: 800;
}

.empresas-card-button {
  /* width: 100%; */
  height: 60px;
}

.empresas-card-tick {
  font-size: 4rem;
  margin: 5px 0 15px;
  /* color: rgb(137, 255, 137); */
  transform: rotate(9deg);
}

.empresas-card-bolt {
  font-size: 4rem;
  margin: 5px 0 15px;
  /* color: rgb(137, 255, 137); */
  transform: rotate(9deg);
}

.empresas-card-logo {
  width: 40%;
  margin: -10px 0 0;
}

.empresas-card-box {
  background-color: #ffffff;
  border-radius: 4px;
  padding: 10px 15px;
  box-shadow: 0px 29px 29px -20px rgba(0, 0, 0, 0.08);
}

.fa-angle-double-right {
  position: relative;
  top: 2px;
}
.empresas-card-grid {
  display: flex;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 1080px;
  justify-content: space-between;
  align-items: center;
  margin-top: 130px;
}

@media (max-width: 1180px) {
  .empresas-card-grid {
    display: flex;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .empresas-card {
    margin-bottom: 30px;
  }

  .empresas-text-box {
    padding: 0 20px;
  }

  .empresas-text-box h1 {
    font-size: 2.4rem;
  }
}

@media (min-width: 1180px) {
  .empresas-card {
    transform: scale(0.94) translateY(2%);
  }
  .empresas-card-grid .empresas-card:nth-child(even) {
    transform: translateY(-12%) scale(1.04);
    box-shadow: 0px 14px 37px -8px rgba(0, 0, 0, 0.13);
  }
}
