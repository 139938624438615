.left-section {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 20px;
  align-items: center;
  z-index: 3;
  max-width: 420px;
  background-color: white;
  box-shadow: 29px 29px 19px -20px rgba(0, 0, 0, 0.02);
  border-right: solid 2px #f9f7ff;

}

.left-section-title-div {
  padding: 10px 0;
  margin: 15px 0;
  border-radius: 5px;
  width: 350px;
  background-color: transparent;
  transition: all 0.16s ease-in-out;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  /* background-color: #ff4848;
    box-shadow: 0px 22px 15px -10px rgba(0, 0, 0, 0.13); */
  font-size: 1.5rem;
  letter-spacing: 1px;
}

.left-section-icon {
  margin-right: 10px;
  font-size: 1rem;
  position: relative;
  top: 2px;
}
