.e-sign-form-wrapper {
  max-width: 95%;
  font-size: 1rem;
}

.e-sign-form-top-box {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}

.e-sign-form-title {
  font-size: 1rem;
  font-weight: bold;
}

.e-sign-form-title-box {
  align-items: center;
  margin-bottom: 50px;
}

.e-sign-form-title-icon {
  width: 40px;
  margin-right: 8px;
}

.e-sign-form-remaining-box {
  font-size: 1rem;
  border-left: 4px solid #3cc691;
  padding-left: 15px;
  border-radius: 4px;
  height: fit-content;
  position: relative;
  top: 10px;
  right: 10px;
}

.e-sign-form-reqs {
  margin-left: 100px;
}

.e-sign-form-card {
  /* color: rgb(10, 114, 10);
  background-color: white; */
  width: 100%;
  padding: 25px 30px 25px 30px;
  margin-bottom: 40px;
  border-radius: 6px;
  box-shadow: 0px 27px 6px -25px rgba(0, 0, 0, 0.03);
}

.e-sign-form-card span {
  color: rgb(170, 161, 224) !important;
}

.e-sign-form-card label {
  margin-left: 4px;
  font-size: 1rem;
}

.e-sign-form-input {
  width: 100%;
  height: 49px;
  align-self: start;
  margin: 8px 0 20px;
  font-size: 1.1rem;
  padding: 0 15px;
  border: 2px solid #d8e1fe;
  border-radius: 4px;
}

.e-sign-form-input:focus {
  border: solid 2px #a8bbfd;
  background-color: #f6f9fc;
  outline: none;
}

.e-sign-form-submit {
  height: 60px;
  width: 192px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: none;
  cursor: pointer;
  font-size: 1.1rem;
}

.e-signature-confirm-wrapper h4 {
  font-size: 2rem;
}

.e-signature-confirm-wrapper .e-sign-form-card p {
  margin-bottom: 29px;
}

.e-signature-confirm-wrapper .e-sign-form-card {
  padding-top: 50px;
  margin-top: 30px;
}

.e-signature-confirm-wrapper li {
  margin-left: 50px;
  text-decoration: none;
  list-style: none;
}

.e-signature-confirm-wrapper .storybook-button {
  width: 80%;
  margin: 70px 0 !important;
}

.e-sign-form-checkbox-wrapper {
  height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
}

.e-sign-form-checkbox {
  padding-left: 10px;
}

.e-sign-form-checkbox input {
  transform: scale(1.6);
  margin-right: 15px;
  opacity: 0.4;
}

.info-icon {
  padding-right: 15px;
  cursor: pointer;
}
