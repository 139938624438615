.subscribe-screen-wrapper{
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.subscribe-screen-title{
    max-width: 700px;
}

.subscribe-screen-text{
    margin: 20px 0;
    max-width: 700px;
}

.subscribe-screen-people{
    width: 400px;
    max-width: 80%;
}