.xs-button {
  width: 150px;
  height: 45px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transform: scale(1);
  transition: all 0.2s;
  cursor: pointer;
}
