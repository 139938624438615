.document-creator {
  min-height: 900px;
  display: grid;
  grid-template-columns: auto 5fr;
}

.document-creator-mobile {
  display: none;
}

@media (max-width: 1100px) {
  .document-creator {
    display: none;
  }
  .document-creator-mobile {
    display: initial;
  }
}

.document-creator-loading-div {
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.document-creator-log-in{
  width: 200px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 6px;
  margin-top: 40px;
}
