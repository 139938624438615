.table-green {
  color: rgb(172, 216, 147);
}

.table-entry-aproval {
  position: relative;
}

.table-entry-aproval-by {
  color: rgba(0, 0, 0, 0.3);
  position: absolute;
  left: 2px;
  font-size: 0.7rem;
  bottom: -15px;
  font-weight: 400;
}

.table-approve-icon-box {
  position: absolute;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
  top: 50%;
  transform: translateY(-50%);
  /* border: solid 1px rgb(214, 214, 225); */
  justify-content: space-around;
  border-radius: 4px;
  width: 79px;
  height: 40px;
  padding: 10px 0;
  padding-left: 6px;
  /* background-color: white; */
  box-shadow: -1px 4px 8px -2px rgba(0, 0, 0, 0.2);
}

.table-approve-icon-box-closed {
  position: absolute;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
  top: 50%;
  transform: translateY(-50%);
  justify-content: space-around;
  border-radius: 4px;
  height: 18px;
  width: 18px;
  transition: all 0.2s;
  cursor: pointer;
}

.table-approve-icon-box-closed:hover {
  transform: translateY(-50%) rotate(90deg) scale(1.05);
}

.table-approve-icon {
  font-size: 0.8rem;
  padding: 4px;
  border-radius: 4px;
  margin: 0;
  transition: all 0.2s;
  cursor: pointer;
}

.table-approve-icon:hover {
  transform: scale(1.1);
  opacity: 1;
}

.table-plus-icon {
  font-size: 0.6rem;
  margin: 0;
  color: grey;
}
