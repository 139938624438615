.document-section-text {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 15px;
  align-items: center;
}

.document-section-text:hover {
}

.document-section-arrow {
  font-size: 1rem;
  position: relative;
}
.document-section-text-div {
  padding: 10px 0;
  margin: 15px 0;
  border-radius: 5px;
  width: 350px;
  background-color: transparent;
  transition: all 0.16s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
}

.document-section-selected-div {
  /* box-shadow: 0px 22px 15px -10px rgba(0, 0, 0, 0.13); */
  cursor: pointer;
  border-radius: 6px;
  background-color: #e8eeff;
  color: rgb(109, 109, 109);
}

.document-section-check {
  /* color: rgb(106, 250, 106); */
  font-size: 1.8rem;
  margin-right: 34px;
}
