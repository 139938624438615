.docx-toolbar {
  display: flex;
  justify-content: space-between;
  background-color: white;
  padding: 0;
  align-items: center;
  margin: 12px 30px;
  border: solid 8px white;
  border-radius: 8px;
  box-shadow: 0px 12px 32px 2px rgba(0, 0, 0, 0.1);
}

.docx-toolbar-buttons {
  display: flex;
  align-items: center;
}

.docx-toolbar button {
  background-color: #fff;
  border: none;
  height: 40px;
  border-radius: 4px;
  padding: 0 12px;
  margin: 0 12px;
  cursor: pointer;
  animation: docx-toolbar-animation 2s;
  font-size: 0.94rem;
  transition: all 0.3s;
}


.docx-toolbar button:hover {
  background-color: #e8eeff;
}

.docx-toolbar-icon {
  margin-right: 10px;
  color: #a8bbfd
}

.docx-toolbar-title {
  display: flex;
  align-items: center;
  position: relative;
}

.docx-toolbar-name {
  position: relative;
  top: -7px;
}

.docx-toolbar-extension {
  color: rgb(170, 167, 167);
  font-size: 0.7rem;
  position: absolute;
  bottom: 13px;
  left: 69px;
}
.docx-toolbar-word {
  width: 60px;
  margin: 0;
  margin-right: 8px;
}

@keyframes docx-toolbar-animation {
  100% {
    opacity: 0.05;
  }

  50% {
    opacity: 1;
  }

  0% {
    opacity: 0.05;
  }
}
