.selector-box {
  height: 150px;
  width: 380px;
  border-radius: 4px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  box-shadow: 0px 22px 15px -10px rgba(0, 0, 0, 0.13);
  box-shadow: 0px 7px 6px -5px rgba(0, 0, 0, 0.13);
  margin: 35px 0;
  padding: 20px;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  transform: scale(1);
  background-color: #ffffff;
  background-color: #e8f3ff;
}

.selector-box-false:hover {
  background-color: #a8bbfd;
  transform: scale(1.13);
  box-shadow: 0px 27px 19px -19px rgba(0, 0, 0, 0.13);
}

.selector-box-true {
  background-color: #a8bbfd;
  transform: scale(1.07);
  box-shadow: 0px 17px 19px -15px rgba(0, 0, 0, 0.13);
  box-shadow: 0px 7px 6px -5px rgba(0, 0, 0, 0.13);
  box-shadow: 0px 27px 19px -19px rgba(0, 0, 0, 0.13);
}

.selector-box-image {
  width: 100px;
}

.selector-box-title {
  font-family: "roboto";
  font-weight: bold;
  font-size: 1.4rem;
  margin-bottom: 10px;
  transition: all 0.04s ease-in-out;
  color: #a8bbfd;
}

.selector-box-false:hover .selector-box-title {
  color: white;
}

.selector-box-true .selector-box-title {
  color: white;
}

.selector-box-desc {
  min-height: 70px;
  display: flex;
  align-items: flex-start;
  transition: all 0.23s;
}

@media (max-width: 633px) {
  .selector-box {
    height: auto;
    min-height: 150px;
    width: 100%;
  }

  .selector-box-true {
    transform: scale(1.02);
    box-shadow: 0px 17px 19px -15px rgba(0, 0, 0, 0.13);
    box-shadow: 0px 7px 6px -5px rgba(0, 0, 0, 0.13);
    box-shadow: 0px 27px 19px -19px rgba(0, 0, 0, 0.13);
  }

  .selector-box-desc {
    min-height: 70px;
    display: flex;
    align-items: flex-start;
    transition: all 0.23s;
    font-size: 0.89rem;
  }
}
