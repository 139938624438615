.documents-manager-wrapper {
  transition: opacity 0.3s;
  z-index: 1;
  position: relative;
  padding-top: 20px;
}

.documents-manager-top-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.document-manager-upload-button {
  width: 220px !important;
  position: absolute !important;
  right: 40px;
  top: 60px;
}

.document-manager-new-folder-button {
  width: 220px !important;
  position: absolute !important;
  right: 280px;
  top: 60px;
}

.document-manager-length {
  padding: 5px 15px;
  border: solid 1px #d1cef3;
  border-radius: 6px;
  font-weight: bold;
  margin: 0 6px;
}

.document-manager-action-box {
  display: flex;
  justify-content: space-between;
  width: 40%;
  margin: 40px 0 -10px;
  align-items: center;
  position: relative;
  top: 4px;
  z-index: 9;
}

.document-manager-page-toggle-box {
  display: flex;
  /* transform: translateX(-50%); */
  width: 450px;
  justify-content: space-between;
  top: 140px;
}

.document-manager-page-toggle {
  background-color: rgb(241, 241, 253);
  color: rgb(196, 193, 223);
  height: 49px;
  width: 210px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* border: solid 1px rgb(226, 226, 226); */
  transition: all 0.12s;
  cursor: pointer;
  border-radius: 6px;
}

.document-manager-page-toggle-not-selected:hover {
  background-color: #fcfcab;
  color: grey;
  border: none;
  /* transform: translateY(-5%); */
}

.document-manager-page-toggle-selected {
  background-color: #5b55a0;
  color: white;
}
