.status-bar-div {
  margin: 40px 0;
  padding: 0 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.status-bar-outer {
  height: 25px;
  width: 158px;
  box-shadow: 0px 22px 15px -10px rgba(0, 0, 0, 0.13);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
  margin-right: 15px;
  background-color: #5b55a0;
}

.status-bar-mid {
  border-radius: 5px;
  height: 100%;
  width: 100%;
}

.status-bar-inner {
  border-radius: 5px;
  height: 100%;
  transition: all 0.3s ease-in-out;
}

@media (max-width: 500px) {
  .status-bar-div {
    flex-direction: column;
    margin: 30px 0 10px;
  }

  .status-bar-outer {
    margin-bottom: 20px;
  }
}
