/* header component */

.header {
  display: flex;
  grid-template-columns: repeat(2, auto);
  justify-content: space-between;
  align-items: center;
  height: 74px;
  z-index: 5;
  position: relative;
  box-shadow: 0px 29px 19px -20px rgba(0, 0, 0, 0.03);
}

.blob-bar {
  position: absolute;
  width: 100%;
  left: 0;
}

@media (max-width: 900px) {
  .hidden {
    display: none;
  }
}

.logo-word {
  height: 39px;
  cursor: pointer;
  margin-left: 55px;
  position: relative;
}

.logo-word:hover {
  opacity: 0.8;
}

.navbar-dual-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* @media (max-width: 900px) {
  .navbar-dual-box {
    display: none;
  }
} */

.logo-word-2 {
  height: 39px;
  cursor: pointer;
  margin-left: 55px;
  position: relative;
}

.navbar-logo-company {
  height: 46px;
  cursor: pointer;
  margin-left: 10px;
  position: relative;
  transform: translateX(-40px);
  opacity: 0;
  object-fit: cover;
}
.navbar-logo-company.show {
  opacity: 1;
  transform: translateX(0);
  transition: all 1.2s 1.5s;
}

.navbar-company-divide-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 22px;
  width: 25px;
  margin-left: 15px;
  margin-right: 7px;
  transform: scale(0.8) translateX(-10px);
  opacity: 0;
}

.navbar-company-divide-wrapper.show {
  transform: scale(0.8) translateX(0);
  opacity: 1;
  transition: all 1s 0.8s;
}

.navbar-company-divide {
  height: 22px;
  width: 4px;
  border-radius: 5px;
  position: relative;
  left: 10.8px;
  transform: rotate(-180deg);
}

.navbar-company-divide-2 {
  height: 3px;
  width: 22px;
  border-radius: 5px;
  background-color: #5b55a0;
  position: relative;
  right: 2px;
  transform: rotate(-90deg);
}

.show .navbar-company-divide {
  transform: rotate(0);
  transition: all 1s 0.8s;
}

.show .navbar-company-divide-2 {
  transform: rotate(0);
  transition: all 1s 0.8s;
}

.navbar {
  display: grid;
  grid-template-columns: repeat(7, auto);
  grid-gap: 10px;
  font-size: 1.05rem;
  transition: all 0.3s ease-out;
  justify-content: center;
  align-items: center;
}

.nav-element {
  cursor: pointer;
  padding: 0 7px;
  transition: all 0.3s ease-out;
  text-decoration: none;
  color: inherit;
  position: relative;
  height: 20px;
  margin: auto 0;
  top: -3px;
}
.nav-element:hover {
  border-radius: 5px;
  color: #bdaaff;
}

.nav-element-login {
  cursor: pointer;
  padding: 0 27px;
  transition: all 0.3s ease-out;
  text-decoration: none;
  color: inherit;
  position: relative;
  height: 40px;
  margin: auto 0;
  top: -3px;
  display: flex;
  align-items: center;
  margin-left: 20px;
  border-radius: 4px;
  font-weight: bold;
  transition: all 0.2s;
}

.nav-element-login:hover {
  background-color: rgb(252, 252, 160);
  color: grey;
}

.fa-bars {
  position: absolute;
  right: 0;
  margin-right: 20px;
  font-size: 1.7rem;
  top: 50%;
  transform: translateY(-50%);
  display: none;
  cursor: pointer;
}

.nav-user {
  width: 50px;
  margin-left: 40px;
  cursor: pointer;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0;
  transform: scale(1.1) translateY(-2px);
}

@media (min-width: 900px) {
  .header {
    padding-right: 40px;
  }

  .navbar {
    margin-right: 20px;
    height: 100%;
  }

  .nav-element {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
    margin-left: 20px;
  }
}

@media (min-width: 2100px) {
  .header {
    padding: 0 10vw;
  }
}

@media (max-width: 900px) {
  .header {
    z-index: 90;
    display: block;
    position: relative;
  }

  .nav-element {
    margin: 20px 0 0;
  }

  .logo-word {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 25px;
    /* opacity: 0; */
  }

  .navbar {
    display: flex;
    position: relative;
    top: 85px;
    flex-direction: column;
    width: 100%;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    color: #d4d7dd;
  }

  .colapse {
    display: none;
  }

  .navbar.drop {
    opacity: 1;
    top: 102px;
    color: #7f79bb;
    z-index: 90;
    width: 100%;
    background-color: #e8eeff;
  }

  .fa-bars {
    display: initial;
  }

  .nav-element {
    height: 45px;
    border-bottom: solid 2px #ffffff;
    width: 100%;
  }
}
