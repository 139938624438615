.main-section-wrapper {
  position: relative;
}
.main-section {
  padding: 20px 50px;
  position: relative;
  z-index: 2;
  max-width: 1050px;
  left: 50%;
  transform: translateX(-50%);
}

.main-section-questions {
  /* display: flex;
    flex-direction: column;
    align-items: center; */
}

.main-sections-questions-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.main-title-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0 50px;
}

.main-logo {
  width: 100px;
  object-fit: contain;
  opacity: 0.3;
}

.main-title-icon {
  margin-right: 15px;
  font-size: 2rem;
}

.main-title {
  font-size: 1.9rem;
  /* font-family: "Roboto"; */
  letter-spacing: -1px;
  font-weight: 700;
}

.main-subtitle-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.main-subtitle {
  font-size: 1.1rem;
}

.main-saver {
  align-self: flex-end;
}

.bottom-section-icon {
  width: 60px;
  margin-right: 12px;
  position: relative;
  top: 14px;
}

.main-section-circle {
  border: 4px solid #e8eeff;
  border-radius: 25px;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.8;
  }
}

.modal-background {
  width: 100%;
  background-color: black;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  opacity: 0.8;
  animation: 0.2s ease-out 0s 1 opacity;
}

@media (max-width: 884px) {
  .main-saver {
    display: none;
  }
  .main-section {
    padding: 4%;
  }
  .main-title-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0 50px;
  }

  .main-title {
    font-size: 1.9rem;
    font-family: "Roboto";
    letter-spacing: -2px;
    font-weight: 600;
    margin: 0 0 40px;
    text-align: center;
  }

  .main-subtitle {
    font-size: 0.98rem;
  }

  .main-subtitle-text {
    display: grid !important;
    grid-template-columns: 60px auto;
  }
}
