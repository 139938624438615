.folder-name-modal-body {
  background-color: white;
  position: fixed;
  top: 7%;
  left: 50%;
  transform: translateX(-50%);
  width: 500px;
  height: 260px;
  border-radius: 6px;
  padding: 30px 20px;
}

.folder-name-modal-container {
  height: 320px;
  overflow: scroll;
  margin-top: 20px;
}

.folder-name-modal-close {
  font-size: 1.4rem;
  color: rgb(170, 170, 228);
  margin-bottom: 10px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}

.folder-name-modal-title {
  font-size: 1.4rem;
  margin-bottom: 20px;
}

.folder-name-modal-create {
  width: 100%;
}

.folder-name-modal-input {
  width: 100%;
  height: 50px;
  align-self: start;
  margin: 10px 0 0;
  font-size: 1.2rem;
  padding: 0 15px;
  border: 1px solid #a8bbfd;
  border-radius: 5px;
  border-radius: 5px;
  font-size: 0.95rem;
  color: #5b55a0;
}

.folder-name-modal-input-label {
  width: 100%;
  text-align: left;
}
