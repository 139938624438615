.ui-success-modal-background {
  z-index: 9001;
  background-color: rgba(0, 0, 0, 0.35);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  animation: modal-animation 0.12s ease-in;
}

@keyframes modal-animation {
  100% {
    opacity: 1;
  }

  0% {
    opacity: 0;
  }
}

.ui-success-modal-background-children {
  animation: modal-children-animation 0.1s ease-in;
}

@keyframes modal-children-animation {
  100% {
    opacity: 1;
  }

  0% {
    opacity: 0;
  }
}

.ui-success-modal {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  width: 600px;
  border-radius: 6px;
  padding: 20px;
  padding-top: 40px;
  min-height: 250px;
}

.ui-success-modal-message {
  font-weight: bold;
  font-size: 1.5rem;
}

.ui-success-modal-sub-message {
  margin: 20px;
}

.ui-success-modal-button {
  margin-top: 20px;
  position: relative;
  top: 10px;
  width: 60%;
}

.ui-success-modal-close {
  font-size: 1.4rem;
  color: rgb(170, 170, 228);
  margin-bottom: 10px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}

.ui-success-modal-disclaimer {
  color: grey;
  font-size: 0.8rem;
}

.ui-success-modal-close:hover {
  color: rgb(107, 107, 158);
}
