.card {
  width: 100%;
  padding: 25px 30px 25px 30px;
  margin-bottom: 40px;
  border-radius: 10px;
  /* box-shadow: 0px 27px 26px -25px rgba(0, 0, 0, 0.13); */
  box-shadow: 0px 6px 4px -4px rgba(0, 0, 0, 0.09);
  background-color: #ffffff;
  color: #5b55a0;
}

.input {
  height: 30px;
  border-radius: 6px;
  margin: 0;
  padding-left: 8px;
  width: 100%;
  border: 2px solid #f2eefb;
  outline: none;
}
