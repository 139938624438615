.trusted-wrapper {
  display: flex;
  width: 95vw;
  max-width: 1200px;
  justify-content: space-between;
}

.trusted-wrapper div {
  width: 18%;
  box-shadow: 0px 15px 18px -9px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  background-color: #f6f9fc;
  /* border: 3px solid #e8f3ff; */
}

/* .trusted-wrapper div::after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 20%;
  height: 100%;
  pointer-events: none;
  content: "";
  border-radius: inherit;
  box-shadow: inset 0 1px 1px rgba(255, 255, 255, 0.9);
} */

.trusted-wrapper img {
  width: 100%;
}

.trusted-box {
  opacity: 0;
  transition: all 0.22s ease-in;
  transform: translateX(-20px) scale(0.95) rotate(-5deg);
  filter: blur(5px);
}

.show-navbar {
  filter: blur(0);
}

.box-instance-1.show-navbar {
  opacity: 1;
  transition-delay: 0.3s;
  transform: translateX(0);
}

.box-instance-2.show-navbar {
  opacity: 1;
  transition-delay: 0.4s;
  transform: translateX(0);
}

.box-instance-3.show-navbar {
  opacity: 1;
  transition-delay: 0.5s;
  transform: translateX(0);
}

.box-instance-4.show-navbar {
  opacity: 1;
  transition-delay: 0.6s;
  transform: translateX(0);
}

.box-instance-5.show-navbar {
  opacity: 1;
  transition-delay: 0.7s;
  transform: translateX(0);
}
