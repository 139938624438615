.introduction-section {
}

.introduction-title {
  font-size: 2.6rem;
  margin: 20px 0 50px;
  font-family: "Roboto";
  letter-spacing: -1px;
  font-weight: 600;
}

.introduction-div-1 {
  display: grid;
  grid-template-columns: 10fr 4fr;
}

.introduction-price-box {
  height: 45px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  border-radius: 4px;
  margin: 0 0 40px;
  margin-left: 30px;
  position: relative;
}

.introduction-info-icon {
  position: absolute;
  right: 15px;
}

.introduction-icon {
  width: 60px;
  margin-right: 12px;
  position: relative;
  top: 14px;
}

.introduction-description {
  width: 100%;
  margin: 20px 0;
  font-size: 1rem;
}

.introduction-user-image {
  width: 285px;
  max-width: 100%;
  object-fit: contain;
  /* background-color: #cbfdb4; */
  border-radius: 4px;
  height: 100%;
}

.introduction-button {
  margin: 40px 0;
}

.introduction-grid-1 {
  display: grid;
  grid-template-columns: 10fr 4fr;
  align-items: center;
  justify-content: center;
  margin: 30px 0;
}

.introduction-card-1 {
  border-radius: 4px;
  width: 97%;
  position: relative;
  padding-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: auto;
  margin-bottom: 20px;
  padding-top: 20px;
}

.introduction-start-button {
  position: relative;
  align-self: flex-start;
}

.introduction-card-button {
  margin: 0;
  width: 70%;
}

.introduction-card-2 {
  border-radius: 4px;
  width: 97%;
  position: relative;
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  height: 270px;
  align-items: center;
  justify-items: center;
}
.introduction-card-image-2 {
  height: 70px;
}

.introduction-grid-2 {
  display: grid;
  grid-template-columns: 10fr 4fr;
  align-items: center;
}

.introduction-thumbnail {
  width: 480px;
  box-shadow: 0px 9px 6px -5px rgba(0, 0, 0, 0.13);
  margin: 0 0 70px;
  transition: all 0.2s;
  cursor: pointer;
}

.introduction-thumbnail:hover {
  transform: scale(1.02);
  opacity: 0.8;
}

.introduction-video-div {
  padding: 20px;
  padding-left: 30px;
}

.introduction-step {
  margin: 40px 0;
  margin-left: 90px;
}

@media (max-width: 755px) {
  .introduction-section {
    display: none;
  }
}
