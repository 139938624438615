.table-entry-name {
  display: flex;
  align-items: center;
  position: relative;
}
.table-entry-image {
  width: 34px;
  margin-right: 15px;
  border-radius: 6px;
}
.table-entry-image-large {
  width: 34px;
  margin-right: 8px;
}

.table-entry-subtitle {
  color: rgba(0, 0, 0, 0.3);
  position: absolute;
  left: 44px;
  font-size: 0.7rem;
  bottom: -7px;
}

.table-entry-icon {
  font-size: 1.4rem;
  margin-right: 15px;
}

.table-entry-date {
  width: fit-content;
  border-radius: 4px;
}

.table-entry-date-time {
  position: absolute;
  /* opacity: 0.8; */
  bottom: -18px;
  font-style: italic;
  font-size: 0.72rem;
}

.MuiTableRow-hover {
  border-radius: 10px;
  transition: all 0.1s;
}

.MuiTableRow-hover:hover {
  background-color: #faf8ff !important;
  transform: scale(1.005);
}

.table-entry-action-wrapper {
  display: flex;
  position: relative;
}

.table-entry-action-icon-wrapper {
  margin-right: 8px;
  position: relative;
  cursor: pointer;
}

.table-entry-action-icon {
  /* opacity: 0.7; */
  font-size: 0.85rem;
  transition: transform 0.2s, background-color 0.3s, color 0.3s;
  transform: scale(1) rotate(0);
  position: relative;
  z-index: 10;
  padding: 2px;
}

.table-entry-action-icon:hover {
  transform: scale(1.3) rotate(8deg);
  opacity: 1;
  /* background-color: #c2d7ed; */
  border-radius: 8px;
  /* color: white; */
}

.table-entry-action-name {
  border-radius: 4px;
  padding: 2px 6px;
  font-size: 0.75rem;
  position: absolute;
  top: -24px;
  transform: translateX(-59%) scale(0.87);
  color: #827cc6;
  opacity: 0;
  background-color: #c5d7eb;
  color: white;
  font-weight: bold;
  z-index: 4;
}

.table-entry-action-icon:hover + .table-entry-action-name {
  opacity: 1;
  opacity: 1;
  transform: translateX(-39%) scale(0.9) rotate(0);
  transition: all 0.4s 0.7s;
}
