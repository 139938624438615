.e-sign-option-option-card {
  width: 100%;
  height: 55px;
  border-radius: 4px;
  margin: 18px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: all 0.2s;
  transform: scale(1);
  padding: 0 20px;
  font-size: 1rem;
  cursor: pointer;
}

.e-sign-option-option-card-false:hover {
  transform: scale(1.02);
  background-color: #ecf1e5 !important;
  color: #4a8591 !important;
}

.e-sign-option-option-card-false:hover i {
  color: #4a8591 !important;
  transition: all 0.3s;
}

.e-sign-option-option-card-true {
  background-color: #ecf1e5 !important;
  color: #4a8591 !important;
}

.e-sign-option-square {
  margin-right: 28px;
  color: #37317d;
}

.e-sign-option-check {
  margin-right: 28px;
  transform: scale(1.07);
}

@media (max-width: 550px) {
  .e-sign-option-option-card {
    font-size: 0.93rem;
  }

  .e-sign-option-option-card-false:hover {
    transform: scale(1);
  }
}
