.signer-actions-wrapper {
  display: flex;
  border-bottom: solid 1px rgb(223, 223, 249);
  width: fit-content;
  padding-bottom: 6px;
  margin-bottom: 16px;
}

.signer-actions-wrapper .toast {
  position: absolute;
  top: 8px;
  left: 50%;
  transform: translateX(-50%);
  padding: 4px 20px;
  border-radius: 4px;
  font-size: 1rem;
  width: 50vw;
  text-align: center;
  box-shadow: 0px 4px 10px -4px rgba(0, 0, 0, 0.5);
  animation: signer-url-copy-animation 0.2s ease-in;
}

@keyframes signer-url-copy-animation {
  100% {
    opacity: 1;
    transform: translateY(0) translateX(-50%);
  }

  0% {
    opacity: 0;
    transform: translateY(-60%) translateX(-50%);
  }
}
