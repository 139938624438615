.questions-form {
  width: 100%;
}
.next-section-button-div {
  width: 100%;
  justify-content: space-between;
  margin: 60px 0;
  display: flex;
}

.next-section-button {
  height: 63px;
  width: 260px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  transition: all 0.2s;
  font-size: 1.1rem;
  font-weight: 300;
  /* font-family: "Open Sans", sans-serif; */
  transform: scale(1);
  color: rgb(44, 54, 141);
}

.next-section-button:hover {
  border-radius: 7px;
}

.next-section-div {
  position: relative;
  height: 63px;
  transition: all 0.23s;
}
.next-section-div:hover .next-section-button {
  /* transform: scale(0.95); */
  background-color: #fcfcab;
  color: grey;
}

.next-section-button:hover {
  /* transform: scale(0.95); */
  background-color: #fcfcab;
  color: grey;
}

.next-section-trapezoid {
  border-bottom: 63px solid rgb(231, 231, 231, 0.16);
  border-left: 0 solid transparent;
  border-right: 27px solid transparent;
  border-top: 0 solid transparent;
  width: 105px;
  position: absolute;
  left: 0;
  opacity: 1;
  /* border-radius: 8px; */
  border-bottom-left-radius: 4px;
  bottom: 0px;
}

.prev-section-button {
  cursor: pointer;
  transition: all 0.2s;
}

.prev-section-button:hover {
  color: rgb(0, 0, 0);
}

@media (max-width: 633px) {
  .next-section-button-div {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin: 60px 0;
    display: flex;
    flex-direction: column-reverse;
    height: 130px;
  }

  .prev-section-button {
    cursor: pointer;
    transition: all 0.2s;
    margin: 40px 0;
  }
}
