.contact-wrapper {
  padding: 80px 0;
  min-height: 800px;
}

.contact-card {
  width: 800px;
  max-width: 98vw;
  border-radius: 4px;
  padding: 40px 20px 20px;
  box-shadow: 0px 14px 37px -8px rgba(0, 0, 0, 0.08);
  margin: 40px 0;
}

.contact-grid {
  width: 700px;
  max-width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.contact-grid-2 {
  display: grid;
  grid-template-columns: 1fr 1.9fr;
  width: 600px;
  text-align: left;
  justify-content: flex-start;
  padding-top: 0;
  width: 75%;
  max-width: 100%;
}

.contact-logo-box {
  display: flex;
  justify-content: flex-end;
}

.contact-title {
  margin: 20px 0 0;
  /* font-family: 'Roboto';
    font-weight: 700; */
  font-size: 1.8rem;
  text-align: center;
}

.contact-item {
  margin: 50px 0;
  font-size: 1.1rem;
}

.contact-item-1 {
  font-weight: bold;
  font-family: "Roboto";
}

.contact-item-2 {
  text-align: right;
}

@media (max-width: 500px) {
  .contact-card {
    padding: 20px 20px 10px;
  }

  .contact-title {
    font-size: 1.5rem;
  }

  .contact-item {
    margin: 50px 0;
    font-size: 1rem;
  }

  .contact-grid {
    width: 95%;
  }

  .contact-grid-2 {
    width: 95%;
  }
}
