.expiration-modal {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  width: 600px;
  border-radius: 6px;
  padding: 20px;
  padding-top: 40px;
  min-height: 250px;
  display: flex;
  flex-direction: column;
}

.expiration-modal-title {
  font-size: 1.4rem;
}

.expiration-modal-name {
  margin: 20px 0 30px;
  font-style: italic;
}

.expiration-modal-input {
  width: 100%;
  height: 49px;
  align-self: start;
  margin: 12px 0 20px;
  font-size: 1.1rem;
  padding: 0 15px;
  border: 1px solid #a8bbfd;
  border-radius: 5px;
  border-radius: 5px;
  color: rgb(163, 138, 255);
}

.expiration-modal-input:focus {
  /* background-color: yellow; */
  border: solid 2px #89cedc;
  background-color: #f6f9fc;
  outline: none;
  color: #4a8591;
}

.expiration-modal label {
  text-align: left;
  width: 100%;
  padding-left: 3px;
}

.expiration-modal-button {
  margin: 0;
  width: 100%;
}
