.dashboard-summary {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-items: center;
  margin: 40px 0 0;
}

.dashboard-summary-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-width: 230px;
  height: 130px;
  text-align: left;
  padding: 20px 37px;
  /* box-shadow: 0px 29px 22px -20px rgba(185, 185, 185, 0.15);  */
  border-left: outset #ffffff 6px;
  /* border: solid 1px rgb(251, 249, 255); */
  /* background-color: rgb(241, 236, 255); */
}

.dashboard-summary-box h4 {
  font-family: "Roboto";
  font-weight: bold;
  font-size: 2rem;
}

@media (max-width: 1300px) {
  .dashboard-summary-box {
    font-size: 0.9rem;
  }
}

.dashboard-summary-line-back {
  background-color: rgb(226, 225, 245);
  height: 12px;
  border-radius: 8px;
}

.dashboard-summary-line-inner {
  height: 12px;
  border-radius: 8px;
}
