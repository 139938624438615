.text-align-left{
text-align: left;
}

.text-align-center{
    text-align: center;
    }


.text-align-right{
    text-align: right;
    }

    .text-size-xxsmall{
        font-size: 0.6rem;
    }
.text-size-xsmall{
    font-size: 0.8rem;
}

.text-size-small{
    font-size: 1rem;
}

.text-size-medium{
    font-size: 1.1rem;
}

.text-size-xmedium{
    font-size: 1.3rem;
}

.text-size-large{
    font-size: 1.5rem;
}

.text-size-xlarge{
    font-size: 1.9rem;
}

.text-size-xxlarge{
    font-size: 2.2rem;
}

.roboto-true{
    font-family: 'Roboto';
    font-weight: 900;
}