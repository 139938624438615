.footer {
  padding-top: 25px;
  min-height: 470px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  justify-items: center;
  align-items: center;
  position: relative;
  /* z-index: 5; */
  box-shadow: 0px -29px 19px -20px rgba(0, 0, 0, 0.02);
}

.footer-logo {
  width: 270px;
  margin-left: -10px;
}

.footer-grid {
  display: grid;
  grid-template-columns: 2fr 2fr;
  min-height: 300px;
  width: 100%;
  padding: 20px;
  max-width: 1450px;
}

.footer-grid-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.footer-box-1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 130px;
}

.footer-box-2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px 0;
}

.footer-box-3 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px 0;
}

.footer-link {
  text-decoration: none;
  color: #5c54b6;
  font-size: 1.1rem;
  transition: all 0.3s;
}

.footer-chevron {
  margin-right: 10px;
  transform: rotate(0);
  transition: all 0.5s;
}

.footer-link:hover {
  color: rgb(172, 170, 86);
}

.footer-legal-tech {
  width: 230px;
}

.footer-disclaimer-div {
  margin: 20px 0 0;
  max-width: 90%;
}

.footer-disclaimer {
  margin: 10px 0;
  font-size: 0.73rem;
  color: rgb(150, 150, 150);
}

@media (max-width: 1024px) {
  .footer {
    padding-bottom: 20px;
  }

  .footer-grid {
    grid-template-columns: 1fr;
    grid-template-rows: 350px 300px;
    padding: 0 5%;
  }

  .footer-grid-2 {
    display: grid;
    grid-template-columns: 3fr 1fr;
    max-width: 700px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  .footer-box-1 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-left: 0;
    padding: 9px 0 49px;
  }

  .footer-logo {
    margin-left: 0;
  }
  .footer-link {
    font-size: 1.2rem;
  }
}

@media (max-width: 579px) {
  .footer {
    padding-bottom: 20px;
  }

  .footer-grid {
    grid-template-columns: 1fr;
    grid-template-rows: 350px 300px;
    padding: 0 5%;
  }

  .footer-grid-2 {
    display: grid;
    grid-template-columns: 1.8fr 1fr;
    max-width: 700px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  .footer-box-1 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-left: 0;
    padding: 9px 0 49px;
  }

  .footer-logo {
    width: 190px;
    margin-left: 0;
  }
  .footer-link {
    text-decoration: none;
    color: #37317d;
    font-size: 1rem;
    transition: all 0.3s;
  }
}
