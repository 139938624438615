.MuiTextField-root {
  background-color: transparent;
  width: 600px;
  max-width: 43vw;
}

.MuiTextField-root .MuiInputBase-root {
  color: #9e9aca !important;
  width: 100%;
  font-size: 1.1rem;
  padding: 8px 15px;
  border: solid 2px #d7e7f8;
  border-radius: 8px;
  color: #5b55a0;
  font-size: 0.95rem;
  background-color: white;
}

.MuiInputBase-root::before {
  border-bottom: none !important;
}

.MuiInputBase-root::after {
  border-bottom: none !important;
}

.andes-table-icon {
  background-color: #e8f3ff;
  padding: 4px 5px;
  font-size: 0.8rem;
  border-radius: 4px;
  background-color: white !important;
  /* color: white; */
}

.MuiTableSortLabel-root {
  margin-left: 15px !important;
  font-size: 2rem !important;
  color: red !important;
}

.MuiTableSortLabel-icon {
  color: #a8bbfd !important;
  font-size: 0.9rem !important;
}

.Mui-TableHeadCell-Content-Labels {
  overflow: visible !important;
}

.Mui-TableHeadCell-Content-Actions .MuiIconButton-root {
  opacity: 0.8;
  position: relative;
  top: -2px;
  left: -25px;
  transform: rotate(0) scale(0.9);
  transition: all 0.2s;
}

.Mui-TableHeadCell-Content-Actions .MuiIconButton-root:hover {
  opacity: 1;
  transform: rotate(90deg);
  background-color: transparent !important;
}

.MuiMenu-paper {
  background-color: #a8bbfd !important;
  color: white !important;
  box-shadow: 0px 20px 10px -12px rgba(0, 0, 0, 0.2) !important;
  font-family: "Source Sans Pro", sans-serif !important;
  font-weight: 300 !important;
  border-radius: 6px !important;
}

.MuiMenu-paper svg {
  color: #5b55a0 !important;
}
