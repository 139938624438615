.dashboard-selector {
  padding: 0;
}

.dashboard-selector-grid {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  justify-items: center;
  margin: 20px 0 50px;
  max-width: 100%;
}

.dashboard-selector-category {
  font-size: 1rem;
  font-weight: 400;
  margin-left: 0;
  margin-top: -10px;
}

.dashboard-selector-icon {
  margin-right: 10px;
}
