.storybook-button {
  border: 0;
  border-radius: 6px;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  display: flex;
  align-items: center;
  transition: background-color 0.13s, color 0.05s;
  position: relative;
  font-size: 1.1rem;
  margin-top: 20px;
  margin-bottom: 20px;
}

.storybook-button:hover {
  /* border-radius: 8px; */
  /* transform: scale(0.96); */
  /* transform: scale(1.02); */
}
.button-center-true {
  width: 100%;
  display: flex;
  justify-content: center;
}

/* .storybook-button:hover .label {
  left: 30%;
} */

.button-trapezoid {
  border-bottom: 60px solid rgb(231, 231, 231, 0.16);
  border-left: 0 solid transparent;
  border-right: 27px solid transparent;
  border-top: 0 solid transparent;
  width: 105px;
  position: absolute;
  left: 0;
  opacity: 1;
  /* border-radius: 8px; */
  border-bottom-left-radius: 4px;
  /* transition: all 0.13s; */
}

.storybook-button:hover .button-trapezoid {
  border-bottom: 60px solid rgba(255, 255, 255, 0);
}

.button-transparent {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  background-color: transparent;
  border: solid 2px white;
}

.button-transparent:hover {
  background-color: rgb(231, 231, 231, 0.2);
  border: solid 2px white;
}

.storybook-button--small {
  width: 192px;
  height: 60px;
}
.storybook-button--medium {
  width: 282px;
  height: 60px;
}
.storybook-button--large {
  width: 420px;
  height: 60px;
  max-width: 80%;
}

.storybook-button--full-width {
  width: 100%;
  height: 54px;
}

.button-label {
  font-weight: 400;
}

.label--small,
.label--medium,
.label--large,
.label--full-width,
.label--still {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  /* transition: all 0.13s; */
}

.button-icon-medium {
  opacity: 0;
  position: absolute;
  right: 15px;
  font-size: 1rem;
  /* transition: all 0.13s; */
}

.button-icon-large {
  opacity: 0;
  position: absolute;
  right: 65px;
  font-size: 1rem;
  /* transition: all 0.13s; */
}

.storybook-button:hover .button-icon-medium {
  opacity: 1;
  right: 10px;
}

.storybook-button:hover .button-icon-large {
  opacity: 1;
  right: 13px;
}

.custom-button:hover {
  background-color: #fcfcab !important;
}
