@import url("https://cdn.syncfusion.com/ej2/20.3.56/bootstrap5.css");

.docx-ditor-wrapper {
  margin: 12px 30px;
  border: solid 8px white;
  border-radius: 8px;
  box-shadow: 0px 12px 32px 2px rgba(0, 0, 0, 0.1);
}

.e-de-ctn {
  border: 2px solid white;
}

.docx-loader {
  position: absolute;
  top: 125px;
  left: 50%;
  transform: translateX(-50%);
}
/* .e-documenteditorcontainer button:hover {
  background-color: #a8bbfd !important;
}

.e-documenteditorcontainer button {
  background-color: #f6f9fc !important;
}
.e-documenteditorcontainer button:hover span {
  color: white !important;
}

.e-documenteditorcontainer span {
  color: #5b55a0 !important;
}

.e-de-tool-ctnr-properties-pane {
  background-color: #f6f9fc;
}

.e-de-ctn {
  background-color: #f6f9fc;
}

.e-hscroll-content {
  background-color: #f6f9fc;
}

.e-de-op {
  background-color: #f6f9fc;
}

.e-input-group {
  background-color: #ffffff;
}

.e-input-group-icon {
  background-color: #f6f9fc;
}
.e-de-status-bar {
  background-color: #f6f9fc;
  color: #5b55a0 !important;
}

.e-scroll-nav {
  background: none !important;

  background-color: #f6f9fc !important;
}

.e-de-background {
  background-color: #f6f9fc;
}

.e-toolbar-item.e-overlay {
  background-color: #f6f9fc !important;
}

.e-toolbar-item.e-active {
  background-color: #a8bbfd !important;
}

.e-tab-text {
  color: #5b55a0 !important;
}

.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-text {
  color: white !important;
}

.e-btn-toggle {
  background-color: #a8bbfd !important;
} */
