.question-card {
  background-color: white;
  width: 100%;
  padding: 25px 30px 25px 30px;
  margin-bottom: 40px;
  border-radius: 6px;
  box-shadow: 0px 27px 26px -25px rgba(0, 0, 0, 0.13);
}

.question-format {
  width: 90%;
  margin: 40px 0;
  justify-content: flex-start;
}

.question-format-form {
  display: flex;
  justify-content: flex-start;
  position: relative;
}

.question-format-form-numberOf {
  display: flex;
  justify-content: flex-start;
  position: relative;
  width: 450px;
}

.question-format-input {
  width: 100%;
  height: 49px;
  align-self: start;
  margin: 20px 0;
  font-size: 1.1rem;
  padding: 0 15px;
  border: 1px solid #a8bbfd;
  border-radius: 5px;
  border-radius: 5px;
  color: rgb(163, 138, 255);
  font-size: 0.95rem;
}

.question-format-input-numberOf {
  width: 450px;
  height: 49px;
  align-self: start;
  margin: 20px 0;
  font-size: 1.1rem;
  padding: 0 15px;
  border: 1px solid #a8bbfd;
  border-radius: 5px;
  border-radius: 5px;
  color: rgb(163, 138, 255);
  font-size: 0.95rem;
}

.question-format-text {
  width: 100%;
  line-height: 30px;
}

.question-format-info {
  width: 100%;
  opacity: 0.8;
  padding-left: 45px;
  padding-right: 45px;
  font-size: 0.94rem;
}

.question-format-info-bottom {
  width: 100%;
  opacity: 0.8;
  font-size: 0.94rem;
  padding-left: 15px;
}

.currentAnswer {
  /* color: rgb(0, 148, 44); */
  font-size: 1rem;
  /* margin: 10px 0 50px; */
  margin: 20px 0 20px;
}

.currentAnswer-check {
  font-size: 1.2rem;
  position: relative;
  top: 2px;
}

.question-format-text-area {
  height: 200px;
  width: 100%;
  align-self: start;
  margin: 20px 0;
  font-size: 0.9rem;
  padding: 15px 15px;
  border: 1px solid #a8bbfd;
  border-radius: 5px;
  border-radius: 5px;
  color: rgb(163, 138, 255);
  font-size: 0.95rem;
  font-family: "Open Sans", sans-serif;
}

.question-format-endfix {
  position: absolute;
  top: 50%;
  transform: translateY(-53%);
  right: 20px;
}

.question-format-input-money {
  width: 35%;
  height: 49px;
  align-self: start;
  margin: 20px 0;
  font-size: 1.1rem;
  padding: 0 15px;
  border: 1px solid #a8bbfd;
  border-radius: 5px;
  border-radius: 5px;
  color: rgb(163, 138, 255);
  font-size: 0.95rem;
  margin-right: 12px;
}

.question-format-input-decimals {
  width: 15%;
  height: 49px;
  align-self: start;
  margin: 20px 0;
  font-size: 1.1rem;
  padding: 0 15px;
  border: 1px solid #cecece;
  border-radius: 5px;
  border-radius: 5px;
  color: rgb(163, 138, 255);
  font-size: 0.95rem;
  margin-right: 12px;
}

.question-format-input-currency {
  width: 30%;
  height: 49px;
  align-self: start;
  margin: 20px 0;
  font-size: 1.1rem;
  padding: 0 15px;
  border: 1px solid #cecece;
  border-radius: 5px;
  border-radius: 5px;
  color: rgb(163, 138, 255);
  font-size: 0.95rem;
}

@media (max-width: 550px) {
  .question-card {
    width: 100%;
    padding: 20px 3%;
    margin-bottom: 40px;
    border-radius: 6px;
    box-shadow: 0px 7px 6px -5px rgba(0, 0, 0, 0.13);
    box-shadow: 0px 27px 26px -25px rgba(0, 0, 0, 0.13);
    color: rgb(46, 46, 46);
  }

  .question-format-text {
    width: 100%;
    line-height: 30px;
    font-size: 0.95rem;
  }

  .question-format-info {
    width: 100%;
    padding-left: 15px;
    padding-right: 0px;
    font-size: 0.85rem;
  }

  .question-format-input {
    width: 100%;
    height: 49px;
    align-self: start;
    margin: 20px 0;
    font-size: 1.1rem;
    padding: 0 15px;
    border-radius: 5px;
    border-radius: 5px;
    font-size: 0.93rem;
  }

  .question-format-input-numberOf {
    width: 100%;
  }

  .question-format-form-numberOf {
    width: 100%;
    font-size: 1rem;
  }

  .currentAnswer {
    font-size: 0.9rem;
    /* margin: 10px 0 50px; */
    margin: 10px 0 10px;
  }

  .currentAnswer-check {
    font-size: 1rem;
    position: relative;
    top: 2px;
    margin-left: 10px;
  }

  .question-format-money-div {
    display: grid;
    grid-template-columns: 3fr 1.2fr;
  }

  .question-format-input-money {
    width: 96%;
    margin-right: 12px;
    font-size: 0.9rem;
    margin-bottom: 0;
  }

  .question-format-input-decimals {
    width: 100%;
    margin-right: 12px;
    font-size: 0.8rem;
    grid-template-rows: 40px 40px;
    margin-bottom: 0;
  }

  .question-format-input-currency {
    width: 98%;
    font-size: 0.95rem;
  }
}
