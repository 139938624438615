.document-selector {
  padding: 50px;
}

.document-selector-grid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  justify-items: center;
  margin: 20px 0 50px;
  max-width: 1340px;
  padding-left: 65px;
}

.document-selector-text-box {
  /* padding-left: 70px; */
  margin-top: 20px;
  text-align: left;
  max-width: 1200px;
  position: relative;
  transform: translateX(-50%);
  left: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 230px;
}

.document-selector-title {
  text-align: center;
  font-size: 3rem;
  margin-bottom: 25px;
  width: 100%;
  text-align: left;
}

.document-selector-pencil {
  width: 200px;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translatey(-50%);
}

.document-selector-intro {
  text-align: left;
}

@media (max-width: 1308px) {
  .document-selector-grid {
    padding-left: 0;
  }
}

@media (max-width: 1230px) {
  .document-selector-grid {
    grid-template-columns: 1fr 1fr;
  }
  .document-selector-pencil {
    display: none;
  }
}

/* @media (max-width: 837px) {
  .document-selector-grid {
    grid-template-columns: 1fr;
  }
  .document-selector-title {
    text-align: center;
    font-size: 2.4rem;
    margin-bottom: 25px;
    letter-spacing: -1px;
  }

  .document-selector-check {
    font-size: 1.4rem;
    margin-left: 10px;
    color: rgb(192, 192, 192);
    position: relative;
    bottom: 2px;
  }
} */

.document-selector-icons {
  width: 130px;
  margin-top: 50px;
}
